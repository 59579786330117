import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import App from './pages/App'
import Filter from './pages/Filter'
import Obrigado from './pages/Obrigado'
import Simulador from './pages/Simulador'

const Rotas = () => {
  
  return (
    <HashRouter basename='/'>
        <Routes>
            <Route path='/' element={<App />} exact />
            <Route path='/orcamento/:tipo/:vinil' element={<Filter/>} />
            <Route path='/simulador' element={<Simulador/>} />
            <Route path='/obrigado/:nome/:dimensoes/:pacote/:desc/:vinil' element={<Obrigado/>} />
        </Routes>
    </HashRouter>
  )
}

export default Rotas