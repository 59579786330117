import React from 'react'
import { FaBars } from 'react-icons/fa'
import {
    MobileIcon,
    Nav,
    NavbarContainer,
    NavbarItem,
    NavbarLink,
    NavbarList,
    NavbarLogo
} from './NavbarElements'

const Navbar = ({toggle}) => {
  return (
    <>
        <Nav>
            <NavbarContainer>
                <NavbarLink spy={true} offset={-100} smooth={true} duration={500} to="home"> <NavbarLogo /></NavbarLink>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavbarList>
                    <NavbarItem>
                        <NavbarLink spy={true} offset={-100} smooth={true} duration={500} to="home"> Home </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink spy={true} offset={-100} smooth={true} duration={500} to="quem-somos"> Quem Somos </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink spy={true} offset={-100} smooth={true} duration={500} to="vinil"> Piscinas </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink spy={true} offset={-100} smooth={true} duration={500} to="ref"> Reformas </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink spy={true} offset={-100} smooth={true} duration={500} to="contatos"> Contatos </NavbarLink>
                    </NavbarItem>
                </NavbarList>
            </NavbarContainer>
        </Nav>
    </>
  )
}

export default Navbar